import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Gallery from "../components/Gallery";
import toast from "react-hot-toast";
import axios from "axios";
import './UserForm.scss'


const UserForm = ({ isEditMode = false }) => {
    const [fullName, setFullName] = useState('');
    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [uploadedImage, setUploadedImages] = useState('')
    const [selectingFor, setSelectingFor] = useState(null);
    const [showGallery, setShowGallery] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialData, setInitialData] = useState({});
    const [avatar, setAvatar] = useState("");
    const [coverImage, setCoverImage] = useState('')
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const { userId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUploadedImage();
    }, []);

    useEffect(() => {
        if (userId) {
            fetchUserData();
        }
    }, [userId]);

    const fetchUploadedImage = async () => {
        try {
            const response = await axios.get(`https://api.newsnepalnetwork.com/api/v1/images`);
            setUploadedImages(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error("Error fetching uploaded avatar:", error);
            toast.error("Error fetching uploaded avatar");
        }
    };

    const fetchUserData = async () => {
        try {
            const initialData = await axios.get(
                `https://api.newsnepalnetwork.com/api/v1/users/${userId}`
            );
            const userData = initialData.data.data;
            setInitialData(userData);

            setUserName(userData.username);
            setEmail(userData.email);
            setAvatar(userData.avatar);
            setCoverImage(userData.coverImage);
            setFullName(userData.fullName);
            setRole(userData.role)
        } catch (error) {
            console.error("Error fetching user data:", error);
            toast.error("Error fetching user data");
        } finally {
            setLoading(false);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate input fields
        if (!fullName.trim() || !username.trim() || !email.trim() || !role) {
            toast.error("Please, Fill out all required fields !");
            return;
        }

        if (!fullName.includes(" ") || fullName.trim().split(" ").length < 2) {
            toast.error("Full name must contain at least one space between first and last name.");
            return;
        }
        if (username.length < 5) {
            toast.error("Username must be at least 5 characters long.");
            return;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            toast.error("Invalid email format.");
            return;
        }
        if (password.length < 8 || password.length > 16) {
            toast.error("Password must be between 8 to 16 characters long.");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("fullName", fullName);
        formData.append("username", username);
        formData.append("email", email);
        formData.append("role", role);
        if (password) formData.append("password", password);
        if (avatar) formData.append("avatar", avatar); // Ensure avatar is appended to FormData
        if (coverImage) formData.append("coverImage", coverImage); // Ensure coverImage is appended to FormData

        try {
            let response;
            if (isEditMode) {
                response = await axios.patch(
                    `https://api.newsnepalnetwork.com/api/v1/users/${userId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
                );
                toast.success("User updated successfully!");
            } else {
                response = await axios.post(
                    `https://api.newsnepalnetwork.com/api/v1/users`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
                );
                toast.success("User created successfully!");
            }
            console.log("User created/updated successfully:", response.data);
            navigate("/dashboard/users");
        } catch (error) {
            console.error("Error creating/updating user:", error.message || error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(`Error: ${error.response.data.message}`);
            } else {
                toast.error(`Error ${isEditMode ? "Updating" : "Creating"} User!`);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSelectImage = (image) => {
        if (selectingFor === "avatar") {
            setAvatar(image.url);
        } else if (selectingFor === "coverImage") {
            setCoverImage(image.url)
        }
        setShowGallery(false);
    };


    return (
        <div className='writer-wrapper'>
            <div className='writer-head'>
                <p>{isEditMode ? "Edit User" : "Add User"}</p>
                <Link to='/dashboard/users'>Users</Link>
            </div>
            <div className="form-sec">
                <form onSubmit={handleSubmit} action="">
                    <div className='input-row'>
                        <div className='input-sec'>
                            <label htmlFor="">Full Name <span className="required">*</span></label>
                            <input type="text" value={fullName || ''} onChange={(e) => setFullName(e.target.value)} required name="" id="" placeholder='your name' />
                        </div>
                        <div className='input-sec'>
                            <label htmlFor="">Username <span className="required">*</span></label>
                            <input type="text" value={username || ''} onChange={(e) => setUserName(e.target.value)} required name="" id="" placeholder='your username' />
                        </div>
                        <div className='input-sec'>
                            <label htmlFor="role">Role <span className="required">*</span></label>
                            <select name="role" onChange={(e) => setRole(e.target.value)} id="role">
                                <option value="Editor">--select one--</option>
                                <option value="Admin">Admin</option>
                                <option value="Editor">Editor</option>
                                <option value="Subscriber">Subscriber</option>
                            </select>
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='input-sec'>
                            <label htmlFor="">Email <span className="required">*</span></label>
                            <input type="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} required name="" id="" placeholder='your email' />
                        </div>
                        <div className='input-sec'>
                            <label htmlFor="">Password <span className="required">*</span></label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required={!isEditMode} name="" id="" placeholder='your password' />
                        </div>
                    </div>
                    <div className="avatar-cover">
                        <div className="avatar">
                            <h3>Avatar<br /> (Optional)</h3>
                            {avatar ? <img src={avatar} alt="Avatar" onClick={() => {
                                setSelectingFor("avatar");
                                setShowGallery(true);
                            }} /> : <h4 onClick={() => {
                                setSelectingFor("avatar");
                                setShowGallery(true);
                            }}>Select Profile</h4>}
                        </div>
                        <div className="coverImage" >
                            <h3>Cover Image<br /> (Optional)</h3>
                            {coverImage ? <img src={coverImage} alt="Cover" onClick={() => {
                                setSelectingFor("coverImage");
                                setShowGallery(true);
                            }} /> : <h4 onClick={() => {
                                setSelectingFor("coverImage");
                                setShowGallery(true);
                            }}>Select Cover</h4>}
                        </div>
                    </div>
                    <div className='btn-sec'>
                        {errorMessage && <div>{errorMessage}</div>}
                        <button type="submit">{isEditMode ? "Update User" : "Add User"}</button>
                    </div>
                </form>
            </div>
            {showGallery && (
                <Gallery
                    setShowGallery={setShowGallery}
                    images={uploadedImage}
                    onSelectImage={handleSelectImage}
                />
            )}
        </div>
    );
};

export default UserForm;