import React, { useEffect, useState } from 'react';
import './AdminIndex.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoMdClose } from "react-icons/io";

const AdminIndex = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [publishedCount, setPublishedCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(true);

  useEffect(() => {
    let url = `https://api.newsnepalnetwork.com/api/v1/posts`
    axios.get(url).then((response) => {
      if (response.data && response.data.data && response.data.data.posts) {
        const fetchedPosts = response.data.data.posts
        setPosts(fetchedPosts);

        // Calculate the counts
        const publishedPostsCount = fetchedPosts.filter(post => post.isPublished).length;
        const draftPostsCount = fetchedPosts.filter(post => !post.isPublished).length;

        // Set the counts
        setPublishedCount(publishedPostsCount);
        setDraftCount(draftPostsCount);

      } else {
        console.error("Invalid response format:", response.data);
      }
    }).catch((error) => {
      setError(error);
    });
  }, []);

  const handleClose = () => {
    setIsWelcomeVisible(false);
  };

  return (
    <div className='newsarea'>
      <div className="noticearea">
        <p>Alert: Notifications from Server !</p>
      </div>
      {isWelcomeVisible && (
        <div className="welcome-area">
          <h1><Link to='https://techchuli.com/'>Welcome to TechChuli Solutions !</Link></h1>
          <div className='close-btn' onClick={handleClose}>
            <IoMdClose />
          </div>
        </div>
      )}
      <div className='counter'>
        <div className='count'>
          <p>{posts.length}</p>
          <p>All News</p>
        </div>
        <div className='count'>
          <p>updating..</p>
          <p>Pending News</p>
        </div>
        <div className='count'>
          <p>{publishedCount}</p>
          <p>Published News</p>
        </div>
        <div className='count'>
          <p>{draftCount}</p>
          <p>News in Draft</p>
        </div>
        <div className='count'>
          <p>updating..</p>
          <p>Writers</p>
        </div>
      </div>
    </div>
  )
}

export default AdminIndex