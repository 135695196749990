import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import PostForm from "./PostForm";

const EditPost = () => {
    const { postId } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const response = await axios.get(`https://api.newsnepalnetwork.com/api/v1/posts/${postId}`);
                
            } catch (error) {
                console.error('Error fetching post data:', error);
                toast.error('Error fetching post data');
            } finally {
                setLoading(false);
            }
        };

        if (postId) {
            fetchPostData();
        }
    }, [postId]);


    if (loading) {
        return <div>Loading...</div>;
    }

    return(
        <PostForm isEditMode={true} />
    );
};

export default EditPost;
